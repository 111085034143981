import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductShopify() {

    const shopifyProductId = '7104056131751'
    const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

    const sizes = {
        "HALF WAIST WIDTH": [
            "",
            "12½/31,5",
            "13/33,5",
            "14/35,5",
            "14¾/37,5",
            "14½/39,5",
            "",
            "",
            ""
        ],
        "HALF HIPS WIDTH": [
            "",
            "14/35,5",
            "15/38",
            "16/40,5",
            "16½/42",
            "17¼/44",
            "",
            "",
            ""
        ],
        "INSIDE LEG LENGTH": [
            "",
            "26½/67,5",
            "27/68,5",
            "27¼/69,5",
            "27¾/70,5",
            "28/71",
            "",
            "",
            ""
        ],
        "HALF KNEE WIDTH": [
            "",
            "6/15",
            "6¼/16",
            "6½/17",
            "7/18",
            "7½/19",
            "",
            "",
            ""
        ]
    }


    return (
        <Layout>
            <SEO title="Shop - Carmine Leggings" />

            <ProductV2 productId={base64ProductId} sizes={sizes} />
        </Layout>
    )

} export default ProductShopify